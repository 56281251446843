import { I18N } from 'aurelia-i18n';
import { ICar } from 'domain/Car/ICar';
import { Utils } from 'utils/helpers/utils';
import { Router } from 'aurelia-router';
import { LogManager, autoinject, observable } from "aurelia-framework";
import { CarService } from "services/car/car-service";
import { IVehicle } from 'domain/Car/IVehicle';
import $ from 'jquery';

export const log = LogManager.getLogger('app.car.vehicles');

@autoinject
export class Vehicles {
  car: ICar;
  vehicles: IVehicle[];
  manuId: number;
  modelId: number;

  query: string;
  error: string;
  @observable filter: string;

  constructor(
    private carService: CarService,
    private router: Router,
    private i18n: I18N
  ) { }

  activate(parameters: any) {
    if (parameters.query)
      this.query = decodeURIComponent(parameters.query);

    if ("manuId" in parameters) {
      this.manuId = parameters.manuId;
      this.modelId = parameters.modelId;

      this.loadVehicles();

      if (this.query)
        this.loadCar();
    } else {
      this.loadVehiclesByQuery();
    }
  }

  // #region MAIN

  filterChanged(term: string) {
    term = term.toLowerCase();

    $(() => {
      $('.vehicle').each(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(term) > -1);
      });
    });
  }

  // #endregion

  // #region LOAD

  loadVehiclesByQuery() {
    this.carService.fetchVehiclesByQuery(this.query).then(result => {
      if (Utils.isArrayEmpty(result)) {
        this.error = this.i18n.tr('pages.vehicles.errors.not-found', { 'query': this.query });
        return;
      }

      const firstVehicle = result[0];

      // Does result actually contain models or manufacturers?
      if (firstVehicle.id == 0) {
        // Result only contains manufacturers
        if (firstVehicle.model.id == 0) {
          this.router.navigateToRoute('carModels', {
            id: firstVehicle.model.manufacturer.id,
            name: Utils.encode(firstVehicle.model.manufacturer.name),
            query: Utils.encode(this.query)
          });
          return;
        }

        this.router.navigateToRoute('carVehicles', {
          manuId: firstVehicle.model.manufacturer.id,
          modelId: firstVehicle.model.id,
          query: Utils.encode(this.query)
        });
        return;
      }

      if (result.length == 1) {
        this.openCatalogue(firstVehicle.id);
        return;
      }

      this.manuId = firstVehicle.model.manufacturer.id;
      this.modelId = firstVehicle.model.id;
      this.vehicles = result;

      this.loadCar();
      Utils.loadSortable();
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  loadCar() {
    this.carService.fetchCarByQuery(this.query).then(result => {
      this.car = result;
      this.fillCar(result);

      Utils.loadSortable();
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  loadVehicles() {
    this.carService.fetchVehicles(this.manuId, this.modelId).then(result => {
      this.fillCar(result[0]);
      this.vehicles = result;
      Utils.loadSortable();
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  // #endregion

  // #region ACTIONS

  openCatalogue(vehicleId: number) {
    if (this.query) {
      this.router.navigateToRoute('catalogueList', {
        vehicleId: vehicleId,
        query: Utils.encode(this.query)
      });
    } else {
      this.router.navigateToRoute('catalogueList', { vehicleId: vehicleId });
    }
  }

  // #endregion

  fillCar(vehicle: IVehicle) {
    if (!this.car)
      this.car = {} as any;

    this.car.model = vehicle.model;
  }
}
