import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { Router } from 'aurelia-router';
import { IManufacturer } from 'domain/Car/IManufacturer';
import { LogManager, autoinject, observable } from "aurelia-framework";
import { CarService } from "services/car/car-service";
import $ from 'jquery';
import 'bootstrap';

export const log = LogManager.getLogger('app.car.manufacturers');

@autoinject
export class Manufacturers {
  manufacturers: IManufacturer[];
  error: string;
  @observable filter: string;

  hasFocus = true;

  constructor(
    private carService: CarService,
    private router: Router,
    private i18n: I18N
  ) { }

  activate() {
    this.carService.fetchManufacturers().then(manufacturers => {
      this.manufacturers = manufacturers;
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  loadModels(manufacturer: IManufacturer) {
    this.router.navigateToRoute('carModels', {
      id: manufacturer.id,
      name: Utils.encode(manufacturer.name, true)
    });
  }

  filterChanged(term: string) {
    term = term.toLowerCase();

    $(() => {
      $('.manufacturer').each(function () {
        const filterMatches = term.length > 0 && $(this).text().toLowerCase().indexOf(term) > -1;
        const isResetAndPopular = term.length == 0 && $(this).hasClass("popular-manu");

        if (filterMatches || isResetAndPopular)
          $(this).removeClass('d-none');
        else
          $(this).addClass('d-none');
      });
    });
  }
}
