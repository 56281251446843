import { I18N } from "aurelia-i18n";
import { WarehouseService } from "services/client/warehouse-service";
import { IWarehouse } from "domain/Client/IWarehouse";
import { TransportService } from "services/client/transport-service";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import { LogManager, autoinject, bindable } from "aurelia-framework";
import { CartService } from "services/cart/cart-service";
import { ICart } from "domain/Cart/ICart";
import { PriceType } from "utils/enums/PriceType";
import { IProduct } from "domain/Product/IProduct";
import { CartProductService } from "services/cart/cart-product-service";

export const log = LogManager.getLogger("app.cart.index");

@autoinject
export class Index {
  allowedTransportOptions: Map<string, string>;
  warehouses: IWarehouse[];
  otherTransportAddresses: string[];

  cart: ICart;
  error: string;

  // map contents: (warehouseId; transportId)
  selectedTransportsForWarehouses = new Map<number, string>();

  @bindable comment = "";
  selectedTransportAddress = "";

  totalWithoutVat = 0;
  totalVat = 0;
  totalWithVat = 0;

  options = {
    showImages: true,
    showEan: true,
    showStock: true,
    showWarehouse: true,
    showReason: false,
    showDisplayPrice: true,
  };

  constructor(
    private cartProductService: CartProductService,
    private cartService: CartService,
    private transportService: TransportService,
    private warehouseService: WarehouseService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig,
    private i18n: I18N,
  ) {
    this.eventAggregator.subscribe(TKEvent.updateCartTotal, () => {
      this.updateTotal();
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsChanged, () => {
      this.updateTotal();
      this.updateSelectedTransports();
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsWarehouseChanged, (product: IProduct) => {
      this.updateCartProductWarehouseId(product);
      this.updateTotal();
      this.updateSelectedTransports();
    });
  }

  attached() {
    this.loadTransportsThenCart();
    this.loadWarehouses();
  }

  // #region OBSERVABLES, LISTENERS

  selectedTransportsForWarehousesChanged(warehouseId: number, transportId: string) {
    this.selectedTransportsForWarehouses.set(warehouseId, transportId);

    if (!this.cart || !this.cart.products) return;

    const isExternalWarehouse = warehouseId == -1;

    this.cart.products
      .filter(x => (isExternalWarehouse && x.externalWarehouseId) || x.warehouseId == warehouseId)
      .forEach(product => {
        if (product.transportId == transportId) return;

        product.transportId = transportId;
        this.updateSelectedTransports();

        // Send changed product to BE
        const cartProduct = {
          cartProductId: product.cartProductId ?? 0,
          foundBy: product.foundBy,
          code: product.code,
          quantity: parseInt(product.quantity.toString()),
          comment: product.comment,
          warehouseId: product.selectedStock?.warehouse?.id ?? product.warehouseId ?? 0,
          transportId: parseInt(product.transportId ?? "0"),
        } as any;

        if (product.selectedStock?.warehouse?.isExternal) {
          cartProduct.warehouseId = 0;
          cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
        }

        this.cartProductService
          .putExistingProduct(cartProduct)
          .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.update")));
      });
  }

  updateSelectedTransports() {
    if (!this.cart?.products) return;

    this.selectedTransportsForWarehouses = new Map<number, string>();

    for (const p of this.cart.products) {
      if (p.externalWarehouseId) {
        this.selectedTransportsForWarehouses.set(-1, p.transportId ?? "0");
      } else {
        this.selectedTransportsForWarehouses.set(p.warehouseId ?? 0, p.transportId ?? "0");
      }
    }
  }

  updateCartProductWarehouseId(product: IProduct) {
    const cartProduct = this.cart.products.find(p => p.id == product.id);
    if (!cartProduct) return;

    if (!product.selectedStock?.warehouse?.isExternal) {
      cartProduct.externalWarehouseId = undefined;
      cartProduct.warehouseId = product.selectedStock?.warehouse?.id;
    } else {
      cartProduct.externalWarehouseId = product.selectedStock?.warehouse?.id;
      cartProduct.warehouseId = 0;
    }
    this.preselectOnlyTransportOption();
  }

  updateTotal() {
    if (this.cart == undefined || this.cart.products == undefined) return;

    this.totalWithoutVat = 0;

    this.cart.products.forEach(p => {
      const wholesale = p.selectedStock?.prices.find(price => price.type == PriceType.Wholesale);
      if (wholesale != null) this.totalWithoutVat += (wholesale.withoutVat ?? 0) * p.quantity;
    });
    this.totalWithoutVat = Utils.round(this.totalWithoutVat);

    this.totalVat = Utils.round(this.totalWithoutVat * AppConfig.vatRate);
    this.totalWithVat = this.totalWithoutVat + this.totalVat;
  }

  // #endregion

  // #region LOAD
  loadTransportsThenCart() {
    this.transportService
      .getAllowedTransportOptions()
      .then(result => {
        this.allowedTransportOptions = result;
        this.loadCart();
      })
      .catch(() =>
        Utils.showErrorToast(log, this.i18n.tr("components.transport-options.errors.load")),
      );
  }

  loadWarehouses() {
    this.warehouseService.getWarehouses().then(result => {
      this.warehouses = result;

      this.updateSelectedTransports();
    });
  }

  loadCart() {
    this.cartService
      .fetchCurrentFull()
      .then(result => {
        this.cart = result;

        this.otherTransportAddresses = this.appConfig.contractSettings.otherTransportAddresses;

        for (const product of this.cart.products) {
          product.warehouseId = product.warehouseId ?? 0;
          product.transportId = product.transportId?.toString() ?? "0";

          if (product.warehouseId == 0 && !product.externalWarehouseId)
            product.warehouseId = this.appConfig.userSettings.warehouseId;
        }

        this.updateTotal();
        this.updateSelectedTransports();
      })
      .catch(error => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  placeOrder() {
    let cancel = false;
    this.selectedTransportsForWarehouses.forEach((transportId: string) => {
      if (cancel) return;

      if (!transportId || transportId == "0") cancel = true;
    });

    if (cancel) {
      Utils.showErrorToast(log, this.i18n.tr("components.transport-options.errors.none-selected"));
      return;
    }

    // Add selected transport address to the end of the comment
    if (this.selectedTransportAddress) {
      if (this.comment) this.comment += "; ";
      this.comment +=
        this.i18n.tr("pages.cart.fields.transport-address") + " " + this.selectedTransportAddress;
    }

    this.cartService
      .placeOrder(this.comment)
      .then(() => {
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
        this.router.navigateToRoute("orderIndex");
      })
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.submit")));
  }

  clearCart() {
    if (!confirm(this.i18n.tr("pages.cart.messages.confirm-clear"))) return;

    this.cartService
      .clearCart()
      .then(() => {
        this.loadCart();
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch();
  }

  // #endregion

  // #region HELPERS

  getWarehouseName(warehouseId: number): string {
    if (warehouseId == -1) return this.i18n.tr("components.products.headers.partner-warehouse");

    if (this.warehouses != undefined) {
      for (const warehouse of this.warehouses) {
        if (warehouse.id == warehouseId) return warehouse.name;
      }
    }

    return `ID: ${warehouseId}`;
  }

  anyExternalWarehouseProducts() {
    return this.cart?.products?.some(x => !!x.externalWarehouseId);
  }

  preselectOnlyTransportOption() {
    if (this.allowedTransportOptions.size != 1) return;

    const transportId = this.allowedTransportOptions.keys().next().value;

    this.cart.products.forEach(product => {
      if (product.transportId == transportId) return;

      product.transportId = transportId;
      if (!product.externalWarehouseId) {
        this.selectedTransportsForWarehouses.set(
          product.warehouseId ?? 0,
          product.transportId ?? "0",
        );
      }
    });
  }

  //#endregion
}
