import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";
import { Router } from "aurelia-router";
import { UserService } from "services/identity/user-service";
import { AppConfig } from "app-config";
import { IdentityService } from "services/identity/identity-service";
import { LogManager, autoinject } from "aurelia-framework";
import { TKError } from "utils/enums/TKError";
import { ServiceResponseError } from "utils/classes/error/ServiceResponseError";

export const log = LogManager.getLogger("app.user.login");

@autoinject
export class Login {
  email: string;
  password: string;
  rememberMe = true;

  hasFocus = true;
  loading = false;

  constructor(
    private identityService: IdentityService,
    private userService: UserService,
    private appConfig: AppConfig,
    private router: Router,
    private i18n: I18N
  ) {}

  activate(parameters: any) {
    if (this.appConfig.jwt) this.router.navigateToRoute("home");

    if (parameters.error && parameters.error == TKError.sessionExpired)
      Utils.showErrorToast(log, this.i18n.tr("general.errors.session-expired"));

    this.checkVersion();
  }

  submit(): void {
    this.loading = true;

    this.identityService
      .login(this.email, this.password)
      .then((data) => {
        this.loading = false;

        this.appConfig.setToken(data, this.rememberMe);
        this.userService
          .getSettings()
          .then((result) => this.appConfig.setSettings(result));

        this.router.navigateToRoute("home");
      })
      .catch((error) => {
        this.getErrorMessage(error).then((message) =>
          Utils.showErrorToast(log, message)
        );
        this.loading = false;
      });
  }

  forgotPassword() {
    if (!this.email) {
      Utils.showInfoToast(this.i18n.tr("pages.user.login.messages.no-email"));
      return;
    }

    this.identityService
      .forgotPassword(this.email)
      .then(() => {
        Utils.showSuccessToast(
          this.i18n.tr("pages.user.login.messages.email-sent")
        );
      })
      .catch(() =>
        Utils.showErrorToast(
          log,
          this.i18n.tr("pages.user.login.errors.NotAllowed")
        )
      );
  }

  async getErrorMessage(error: ServiceResponseError): Promise<string> {
    console.error(error);
    
    const message = this.i18n.tr("general.errors.backend", {
      status: error.name,
    });
    if (!(error instanceof ServiceResponseError)) return message;

    const body = await error.getBody();
    switch (body.code) {
      case "LockedOut":
      case "NotAllowed":
        return this.i18n.tr(`pages.user.login.errors.${body.code}`);
    }

    return message;
  }

  async checkVersion() {
    const currentVersion = document
      .querySelector("meta[name=version]")
      ?.getAttribute("content")
      ?.trim();

    if (!currentVersion || currentVersion == "{{VERSION}}") {
      console.warn("Version is not set, probably local build");
      return;
    }

    const remoteVersion = await fetch(
      `/version.txt?random=${new Date().getTime()}`
    ).then((res) => {
      return res.status === 200 ? res.text().then((text) => text.trim()) : null;
    });

    if (!remoteVersion) {
      console.warn("Failed to fetch remote version, probably dev build");
      return;
    }

    if (remoteVersion != currentVersion) location.reload();
  }
}
