import { AppConfig } from 'app-config';
import { EventAggregator } from 'aurelia-event-aggregator';
import { TKEvent } from 'utils/enums/TKEvent';
import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { CategoryService } from 'services/product/category-service';
import { IProduct } from 'domain/Product/IProduct';
import { IResult } from 'domain/IResult';
import { Router } from 'aurelia-router';
import { LogManager, autoinject, observable, bindable } from "aurelia-framework";
import { CarCategoryService } from 'services/car/car-category-service';
import { CarMenuLevel } from 'utils/enums/CarMenuLevel';
import { IBaseVehicle } from 'domain/Car/IBaseVehicle';
import { SearchOrder } from 'utils/enums/SearchOrder';

export const log = LogManager.getLogger('app.category.products');

@autoinject
export class Products {
  categoryId: number;
  vehicleId?: number;

  result?: IResult<IProduct>;
  error: string;

  @observable({ changeHandler: 'parametersChanged' }) orderBy: SearchOrder = SearchOrder.InStock;
  @observable({ changeHandler: 'parametersChanged' }) orderReversed = false;
  @observable({ changeHandler: 'parametersChanged' }) currentPage = 1;

  options = {
    showAmount: false,
    showSum: false,
    showAddToCart: true,
    showRemoveFromCart: false
  };
  categoriesWithFilters = [9, 10];

  @bindable possibleFilters?: object;
  @bindable chosenFilters?: object;

  car?: IBaseVehicle;
  level = CarMenuLevel.Products;

  constructor(
    private categoryService: CategoryService,
    private carCategoryService: CarCategoryService,
    private router: Router,
    private i18n: I18N,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => { this.loadProductsAndFilters(); });
    this.eventAggregator.subscribe(TKEvent.chosenFiltersChanged, (data: any) => { this.chosenFiltersChanged(data); });
  }

  activate(parameters: any) {
    const categoryId = parseInt(parameters.categoryId);

    const vehicleId = parameters.vehicleId ? parseInt(parameters.vehicleId) : undefined;
    const chosenFilters = parameters.filters ? JSON.parse(decodeURIComponent(parameters.filters)) : undefined;

    const page = parseInt(parameters.page);
    const orderBy = parseInt(parameters.orderBy) || this.orderBy;
    const orderReversed = parameters.orderReversed == "true";

    // Don't query results twice
    if (categoryId == this.categoryId && vehicleId == this.vehicleId && chosenFilters == this.chosenFilters
      && page == this.currentPage && orderBy == this.orderBy && orderReversed == this.orderReversed)
      return;

    this.categoryId = categoryId;
    this.vehicleId = vehicleId;
    this.chosenFilters = chosenFilters;
    this.currentPage = page;
    this.orderBy = orderBy;
    this.orderReversed = orderReversed;

    this.loadVehicle();
    this.loadProductsAndFilters();
  }

  parametersChanged(newValue: any, oldValue: any) {
    if (oldValue === undefined || newValue === undefined || this.categoryId === undefined)
      return;

    // Clear the old products so the loading icon could activate again
    this.result = undefined;

    const parameters = {
      categoryId: this.categoryId,
      page: this.currentPage
    } as any;
    if (this.vehicleId)
      parameters.vehicleId = this.vehicleId;
    if (this.orderBy != SearchOrder.InStock) {
      parameters.orderBy = this.orderBy;
      parameters.orderReversed = this.orderReversed;
    }
    if (!Utils.isObjectEmpty(this.chosenFilters))
      parameters.filters = Utils.encode(JSON.stringify(this.chosenFilters), true);

    this.router.navigateToRoute('categoryProducts', parameters);

    // Since navigating to the same route doesn't actually refresh the page
    this.loadProducts();
  }

  chosenFiltersChanged(data: any) {
    // Make sure this is meant for this category
    if (data.categoryId != this.categoryId)
      return;

    this.chosenFilters = data.chosenFilters;

    if (this.currentPage == 1)
      // Nothing to change, must trigger product reload manually
      this.parametersChanged(null, null);
    else
      // Changing the page value will trigger product reload
      this.currentPage = 1;
  }

  loadProductsAndFilters() {
    if (!this.appConfig.userSettings)
      return;

    this.loadProducts();

    // Categories with filters
    if (this.categoriesWithFilters.indexOf(this.categoryId) > -1)
      this.loadFilters();
    else
      this.clearFilters();
  }

  loadFilters() {
    this.categoryService.fetchFilters(this.categoryId, this.vehicleId).then(result => {
      this.possibleFilters = result;
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  clearFilters() {
    this.possibleFilters = undefined;
    this.chosenFilters = undefined;
  }

  loadProducts() {
    if (!this.appConfig.userSettings)
      return;

    const query = this.vehicleId
      ? this.carCategoryService.fetchProducts(this.categoryId, this.vehicleId, this.currentPage, this.orderBy, this.orderReversed, this.chosenFilters)
      : this.categoryService.fetchProducts(this.categoryId, this.currentPage, this.orderBy, this.orderReversed, this.chosenFilters);

    query.then(result => {
      this.result = result;
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  loadVehicle() {
    if (!this.vehicleId) {
      this.car = undefined;
      return;
    }

    this.carCategoryService.fetchVehicle(this.categoryId, this.vehicleId).then(result => {
      this.car = result;
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }
}
