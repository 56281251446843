import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";
import { Router } from "aurelia-router";
import { IManufacturer } from "domain/Car/IManufacturer";
import { LogManager, autoinject, observable } from "aurelia-framework";
import $ from "jquery";
import "bootstrap";
import { CarCategoryService } from "services/car/car-category-service";

export const log = LogManager.getLogger("app.car.manufacturers");

@autoinject
export class Manufacturers {
  categoryId: number;
  manufacturers: IManufacturer[];

  error: string;
  @observable filter: string;

  hasFocus = true;

  constructor(
    private carCategoryService: CarCategoryService,
    private router: Router,
    private i18n: I18N
  ) {}

  activate(parameters: any) {
    this.categoryId = parseInt(parameters.categoryId);

    this.carCategoryService
      .fetchManufacturers(this.categoryId)
      .then((manufacturers) => {
        manufacturers.sort((a, b) => a.name.localeCompare(b.name));

        this.manufacturers = manufacturers;
      })
      .catch((error) => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  loadProducts() {
    this.router.navigateToRoute("categoryProducts", {
      categoryId: this.categoryId,
      page: 1,
    });
  }

  loadModels(manufacturer: IManufacturer) {
    this.router.navigateToRoute("categoryModels", {
      categoryId: this.categoryId,
      manufacturer:
        manufacturer.id != 0
          ? manufacturer.id
          : Utils.encode(manufacturer.name),
    });
  }

  filterChanged(term: string) {
    term = term.toLowerCase();

    $(() => {
      $(".manufacturer").each(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(term) > -1);

        if (term.length != 0 || $(this).hasClass("popular-manu")) return;

        $(this).css("display", "none");
      });
    });
  }
}
