import { IPerson } from 'domain/Client/IPerson';
import { I18N } from 'aurelia-i18n';
import { AppConfig } from 'app-config';
import { LogManager, autoinject } from "aurelia-framework";
import { ContractService } from 'services/client/contract-service';

export var log = LogManager.getLogger('app.contact.index');

@autoinject
export class Index {
  clientManager: IPerson | undefined;

  constructor(
    private contractService: ContractService,
    private appConfig: AppConfig,
    private i18n: I18N
  ) { }

  activate() {
    this.loadClientManager();
  }

  //#region LOAD

  loadClientManager() {
    this.contractService.getClientManager().then(result => {
      this.clientManager = result;
    });
  }

  //#endregion
}
