import { I18N } from 'aurelia-i18n';
import { ICar } from 'domain/Car/ICar';
import { IModel } from 'domain/Car/IModel';
import { Router } from 'aurelia-router';
import { LogManager, autoinject, observable } from "aurelia-framework";
import { CarService } from "services/car/car-service";
import { Utils } from 'utils/helpers/utils';
import $ from 'jquery';
import 'bootstrap';
import 'moment';
import 'bootstrap-sortable';

export const log = LogManager.getLogger('app.Car.Models');

@autoinject
export class Models {
  models: IModel[];
  manufacturerId: number;
  manufacturerName: string;

  car: ICar;
  query: string;

  error: string;
  @observable
  filter: string;

  constructor(
    private carService: CarService,
    private router: Router,
    private i18n: I18N
  ) { }

  activate(parameters: any) {
    this.manufacturerId = parameters.id;
    this.manufacturerName = decodeURIComponent(parameters.name);

    this.car = {
      model: {
        manufacturer: {
          id: this.manufacturerId,
          name: this.manufacturerName
        }
      }
    } as any;

    this.loadModels();

    if (parameters.query) {
      this.query = decodeURIComponent(parameters.query);
      this.loadCar();
    }
  }

  // #region MAIN

  filterChanged(term: string) {
    term = term.toLowerCase();

    $(() => {
      $('.model').each(function () {
        let matchesYear: boolean | undefined = undefined;
        const words: string[] = [];

        term.split(' ').forEach(word => {
          const year = parseInt(word);

          // Is it a valid year
          if (!isNaN(year) && word.length == 4) {
            const defaultMax = new Date().getFullYear();

            const minYear = parseInt($(this).children()[1].innerText.substr(0, 4));
            const maxYear = parseInt($(this).children()[2].innerText.substr(0, 4)) || defaultMax;

            if (minYear <= year && year <= maxYear)
              matchesYear = true;

            // To tell the difference between having no years to match and matching no years 
            if (matchesYear === undefined)
              matchesYear = false;
          } else {
            words.push(word);
          }
        });

        // Match text without valid years
        const matchesText = $(this).text().toLowerCase().indexOf(words.join(' ')) > -1;

        if (matchesYear === undefined)
          $(this).toggle(matchesText);
        else
          $(this).toggle(matchesText && matchesYear);
      });
    });
  }

  // #endregion

  // #region LOAD

  loadModels() {
    this.carService.fetchModels(this.manufacturerId).then(result => {
      this.models = result;
      Utils.loadSortable();
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  loadCar() {
    this.carService.fetchCarByQuery(this.query).then(result => {
      this.car = result;
      this.car.model.manufacturer.id = this.manufacturerId;

      Utils.loadSortable();
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  // #endregion

  // #region ACTIONS

  openVehicles(modelId: number) {
    if (this.query) {
      this.router.navigateToRoute('carVehicles', {
        manuId: this.manufacturerId,
        modelId: modelId,
        query: Utils.encode(this.query)
      });
    } else {
      this.router.navigateToRoute('carVehicles', {
        manuId: this.manufacturerId,
        modelId: modelId
      });
    }
  }

  // #endregion
}
