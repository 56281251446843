import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import { SearchType } from "utils/enums/SearchType";
import { InvoiceService } from "services/sale/invoice-service";
import { IInvoice } from "domain/Sale/IInvoice";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { Router } from "aurelia-router";
import $ from "jquery";
import "bootstrap";
import printJS from "print-js";
import { Collapse } from "bootstrap";

export const log = LogManager.getLogger("app.invoice.index");

@autoinject
export class Index {
  invoices: IInvoice[] | undefined;

  @observable({ changeHandler: "queryChanged" }) currentPage: number;
  lastPage = 1;

  @observable({ changeHandler: "queryChanged" }) searchText = "";
  @observable({ changeHandler: "queryChanged" }) searchDateFrom = "";
  @observable({ changeHandler: "queryChanged" }) searchDateTo = "";

  error: string;

  constructor(
    private invoiceService: InvoiceService,
    private router: Router,
    private appConfig: AppConfig, // used by html
    private i18n: I18N,
    private eventAggregator: EventAggregator
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadInvoices();
    });
  }

  activate(parameters: any) {
    let page = 1;
    if (parameters.page) page = parseInt(parameters.page); // we want to make sure this is definitely a number

    let query = "";
    if (parameters.query) query = decodeURIComponent(parameters.query);

    let dateFrom = "";
    if (parameters.dateFrom) dateFrom = decodeURIComponent(parameters.dateFrom);

    let dateTo = "";
    if (parameters.dateTo) dateTo = decodeURIComponent(parameters.dateTo);

    this.currentPage = page;
    this.searchText = query;
    this.searchDateFrom = dateFrom;
    this.searchDateTo = dateTo;
  }

  attached() {
    if (this.appConfig.userSettings) this.loadInvoices();
  }

  // #region OBSERVABLES

  // General observer for all search variables
  queryChanged(newValue: number | string, oldValue: number | string) {
    // Required value haven't been set yet
    if (oldValue === undefined) return;

    const dateRegex = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])$/;
    if (this.searchDateFrom && !this.searchDateFrom.match(dateRegex)) return;
    if (this.searchDateTo && !this.searchDateTo.match(dateRegex)) return;

    // Clear the old result so the loading icon could activate again
    this.invoices = undefined;

    const params = {} as any;
    if (this.currentPage >= 1) params.page = this.currentPage;
    if (this.searchText) params.query = Utils.encode(this.searchText);
    if (this.searchDateFrom)
      params.dateFrom = Utils.encode(this.searchDateFrom);
    if (this.searchDateTo) params.dateTo = Utils.encode(this.searchDateTo);

    this.router.navigateToRoute("invoiceIndex", params);

    // Since navigating to the same route doesn't actually refresh the page
    if (this.appConfig.userSettings) this.loadInvoices();
  }

  // #endregion

  // #region LOAD

  loadInvoices() {
    this.invoiceService
      .fetchInvoices(
        this.currentPage,
        Utils.encode(this.searchText),
        Utils.encode(this.searchDateFrom),
        Utils.encode(this.searchDateTo)
      )
      .then((result) => {
        this.invoices = result.items;
        this.currentPage = result.currentPage;
        this.lastPage = result.lastPage;

        // make dates UTC ISO8601
        this.invoices.forEach((invoice) => {
          invoice.showOrders = false;
          invoice.created = new Date(invoice.created + "Z");

          invoice.orders.forEach((order) => {
            order.created = new Date(order.created + "Z");
            order.due = new Date(order.due + "Z");
          });
        });

        Utils.loadSortable();
        this.moveRowsListener();
      })
      .catch(
        (error) =>
          (this.error = Utils.getErrorMessage(error, this.i18n))
      );
  }

  // #endregion

  // #region ACTIONS

  searchProduct(query: string) {
    this.router.navigateToRoute("searchResult", {
      query: Utils.encode(query),
      page: 1,
      type: SearchType.Precise,
    });
  }

  toggleOrderList(target: HTMLElement, invoice: IInvoice) {
    invoice.showOrders = true;

    $(() => {
      // Check if child element was clicked instead
      if (
        $(target).prop("tagName") != "TD" &&
        $(target).prop("tagName") != "TR"
      )
        return;

      const invoiceIdElement = document.querySelector(
        `[data-order-list="${invoice.id}"]`
      );
      if (invoiceIdElement)
        new Collapse(invoiceIdElement, { parent: "#accordion" }).toggle();
    });
  }

  openInvoice(invoice: IInvoice) {
    switch (invoice.openMethod) {
      /*case "html":
        this.openHtml(invoice);
        break;*/
      case "xml":
        this.openXml(invoice);
        break;
      case "pdf":
        this.openPdf(invoice);
        break;
      case "print":
        this.openPrint(invoice);
        break;
    }

    invoice.openMethod = undefined;
  }

  // #endregion

  // #region HELPERS

  /*openHtml(invoice: IInvoice) {
    if (invoice.asHtml != undefined) {
      this.openHtmlInNewTab(invoice.asHtml);
      return;
    }

    this.invoiceService.fetchInvoiceAsHtml(invoice.id).then(result => {
      invoice.asHtml = result;
      this.openHtmlInNewTab(invoice.asHtml);
    }).catch(error => Utils.showErrorToast(Utils.checkForUnauthorized(
      this.i18n.tr('pages.invoices.errors.load.html'), error, this.router)));
  }

  openHtmlInNewTab(html: string) {
    let newWindow = window.open();
    if (newWindow != null)
      newWindow.document.write(html);
  }*/

  openXml(invoice: IInvoice) {
    if (invoice.asXml != undefined) {
      this.downloadXml(invoice.asXml, invoice.id.toString());
      return;
    }

    this.invoiceService
      .fetchInvoiceAsXml(invoice.id)
      .then((result) => {
        invoice.asXml = result;
        this.downloadXml(invoice.asXml, invoice.id.toString());
      })
      .catch(() =>
        Utils.showErrorToast(
          log,
          this.i18n.tr("pages.invoices.errors.load.xml")
        )
      );
  }

  downloadXml(xml: string, fileName: string) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const fileDownload = require("js-file-download");
    fileDownload(xml, fileName + ".xml");
  }

  openPdf(invoice: IInvoice) {
    const win = window.open(
      `${this.appConfig.apiUrl}/Invoice/${invoice.id}/Pdf`,
      "_blank"
    );

    if (win != null) {
      win.focus();
    } else {
      Utils.showErrorToast(log, this.i18n.tr("pages.invoices.errors.load.pdf"));
    }
  }

  openPrint(invoice: IInvoice) {
    if (invoice.asPdf != undefined) {
      this.print(invoice.asPdf);
      return;
    }

    this.invoiceService
      .fetchInvoiceAsPdf(invoice.id)
      .then((result) => {
        invoice.asPdf = result;
        this.print(invoice.asPdf);
      })
      .catch(() =>
        Utils.showErrorToast(
          log,
          this.i18n.tr("pages.invoices.errors.load.pdf")
        )
      );
  }

  print(base64: string) {
    base64 = base64.replace("data:application/pdf;base64,", "");
    printJS({ printable: base64, type: "pdf", base64: true });
  }

  // Move order row along with it's parent invoice row when sorted
  moveRowsListener() {
    $(() => {
      $(".sortable").on("sorted", function () {
        $(this)
          .find("tr.order-list-row")
          .each(function () {
            $(this).insertAfter(
              $(`.invoice-row[data-invoice="${$(this).attr("data-invoice")}"]`)
            );
          });
      });
    });
  }

  // #endregion
}
