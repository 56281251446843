import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { Router } from 'aurelia-router';
import { AppConfig } from 'app-config';
import { IdentityService } from 'services/identity/identity-service';
import { LogManager, autoinject } from "aurelia-framework";
import { ServiceResponseError } from 'utils/classes/error/ServiceResponseError';

export var log = LogManager.getLogger('app.user.reset');

@autoinject
export class Reset {
  locale: string;
  token: string;
  email: string;

  password: string;
  rememberMe: boolean = false;

  hasFocus = true;

  constructor(
    private identityService: IdentityService,
    private appConfig: AppConfig,
    private router: Router,
    private i18n: I18N,
    private eventAggregator: EventAggregator
  ) { }

  activate(parameters: any) {
    this.locale = decodeURIComponent(parameters.locale);
    this.email = decodeURIComponent(parameters.email);
    this.token = decodeURIComponent(parameters.token);

    Utils.setLocale(this.eventAggregator, this.i18n, this.locale);
  }

  checkPasswordValidity() {
    Utils.checkPasswordValidity(this.i18n);
  }

  submit(): void {
    this.identityService.resetPassword(this.token, this.email, this.password).then(data => {
      this.appConfig.setToken(data, this.rememberMe);
      this.router.navigateToRoute('home');
    }).catch(error => this.getErrorMessage(error)
      .then(message => Utils.showErrorToast(log, message)));
  }

  async getErrorMessage(error: ServiceResponseError): Promise<string> {
    let message = this.i18n.tr('general.errors.backend', { status: error.name });

    if (!(error instanceof ServiceResponseError))
      return message;

    switch (error.name) {
      case "400":
        return this.i18n.tr("pages.user.reset.errors.PasswordTooShort");
      case "406":
        const body = await error.getBody();
        switch (body.code) {
          case "InvalidToken":
          case "PasswordRequiresLower":
          case "PasswordRequiresUpper":
          case "PasswordRequiresDigit":
            return this.i18n.tr(`pages.user.reset.errors.${body.code}`);
        }
    }

    return message;
  }
}
