import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";
import { Router } from "aurelia-router";
import { LogManager, autoinject, observable } from "aurelia-framework";
import $ from "jquery";
import { CarCategoryService } from "services/car/car-category-service";
import { CarMenuLevel } from "utils/enums/CarMenuLevel";
import { IBaseVehicle } from "domain/Car/IBaseVehicle";

export const log = LogManager.getLogger("app.category.vehicles");

@autoinject
export class Vehicles {
  vehicles: IBaseVehicle[];

  categoryId: number;
  manufacturerId: number;
  manufacturerName: string;
  modelName: string;

  car: {
    model: {
      name: string;
      manufacturer: {
        id: number;
        name: string;
      };
    };
  };
  level = CarMenuLevel.Vehicles;

  error: string;
  @observable filter: string;

  constructor(
    private carCategoryService: CarCategoryService,
    private router: Router,
    private i18n: I18N
  ) {}

  activate(parameters: any) {
    this.categoryId = parseInt(parameters.categoryId);

    if (isNaN(parameters.manufacturerId))
      this.manufacturerName = decodeURIComponent(parameters.manufacturer);
    else this.manufacturerId = parseInt(parameters.manufacturer);

    this.modelName = decodeURIComponent(parameters.model);

    this.loadVehiclesAndCar();
  }

  // #region OBSERVABLES

  filterChanged(term: string) {
    term = term.toLowerCase();

    $(() => {
      $(".vehicle").each(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(term) > -1);
      });
    });
  }

  // #endregion

  // #region LOAD

  loadVehiclesAndCar() {
    this.carCategoryService
      .fetchVehicles(
        this.categoryId,
        this.manufacturerId ?? this.manufacturerName,
        this.modelName
      )
      .then((result) => {
        this.vehicles = result;
        this.manufacturerId = result[0].model.manufacturer.id;
        this.manufacturerName = result[0].model.manufacturer.name;

        this.car = {
          model: {
            name: this.modelName,
            manufacturer: {
              id: this.manufacturerId,
              name: this.manufacturerName,
            },
          },
        };

        Utils.loadSortable();
      })
      .catch((error) => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  openProducts(vehicleId: number) {
    this.router.navigateToRoute("categoryProducts", {
      categoryId: this.categoryId,
      page: 1,
      vehicleId: vehicleId,
    });
  }

  // #endregion
}
