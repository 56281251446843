import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import { SearchType } from "utils/enums/SearchType";
import { AppConfig } from "app-config";
import { ICart } from "domain/Cart/ICart";
import { Utils } from "utils/helpers/utils";
import { OrderService } from "services/sale/order-service";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { Router } from "aurelia-router";
import $ from "jquery";
import "bootstrap";
import { Collapse } from "bootstrap";

export const log = LogManager.getLogger("app.order.index");

@autoinject
export class Index {
  orders: ICart[] | undefined;
  error: string;

  @observable({ changeHandler: "queryChanged" }) currentPage: number;
  lastPage = 1;

  @observable({ changeHandler: "queryChanged" }) searchText = "";
  @observable({ changeHandler: "queryChanged" }) searchDateFrom = "";
  @observable({ changeHandler: "queryChanged" }) searchDateTo = "";

  options = {
    showInvoiceId: false,
    showWarehouse: true,
    showTransport: true,
    showAddToCart: true,
    showAddToReturn: false,
  };

  constructor(
    private orderService: OrderService,
    private router: Router,
    private appConfig: AppConfig, // used by html
    private i18n: I18N,
    private eventAggregator: EventAggregator
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadOrders();
    });
  }

  activate(parameters: any) {
    let page = 1;
    if (parameters.page) page = parseInt(parameters.page); // we want to make sure this is definitely a number

    let query = "";
    if (parameters.query) query = decodeURIComponent(parameters.query);

    let dateFrom = "";
    if (parameters.dateFrom) dateFrom = decodeURIComponent(parameters.dateFrom);

    let dateTo = "";
    if (parameters.dateTo) dateTo = decodeURIComponent(parameters.dateTo);

    this.currentPage = page;
    this.searchText = query;
    this.searchDateFrom = dateFrom;
    this.searchDateTo = dateTo;
  }

  attached() {
    if (this.appConfig.userSettings) this.loadOrders();
  }

  // #region OBSERVABLES

  queryChanged(newValue: number | string, oldValue: number | string) {
    // Required value haven't been set yet
    if (oldValue === undefined) return;

    const dateRegex = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])$/;
    if (this.searchDateFrom && !this.searchDateFrom.match(dateRegex)) return;
    if (this.searchDateTo && !this.searchDateTo.match(dateRegex)) return;

    // Clear the old result so the loading icon could activate again
    this.orders = undefined;

    const params = {} as any;
    if (this.currentPage != 1) params.page = this.currentPage;
    if (this.searchText != "") params.query = Utils.encode(this.searchText);
    if (this.searchDateFrom != "")
      params.dateFrom = Utils.encode(this.searchDateFrom);
    if (this.searchDateTo != "")
      params.dateTo = Utils.encode(this.searchDateTo);

    this.router.navigateToRoute("orderIndex", params);

    // Since navigating to the same route doesn't actually refresh the page
    if (this.appConfig.userSettings) this.loadOrders();
  }

  // #endregion

  // #region LOAD

  loadOrders() {
    this.orderService
      .fetchOrders(
        this.currentPage,
        Utils.encode(this.searchText),
        Utils.encode(this.searchDateFrom),
        Utils.encode(this.searchDateTo)
      )
      .then((result) => {
        this.orders = result.items;
        this.currentPage = result.currentPage;
        this.lastPage = result.lastPage;

        this.orders.forEach((order) => {
          order.closedDateTime = new Date(order.closedDateTime + "Z"); // make it UTC ISO8601

          order.total = 0;
          order.products.forEach(
            (product) =>
              (order.total += (product.finalPrice ?? 0) * product.quantity)
          );
        });

        this.sortedListener();
        Utils.loadSortable();
      })
      .catch(
        (error) =>
          (this.error = Utils.getErrorMessage(error, this.i18n))
      );
  }

  // #endregion

  // #region ACTIONS

  searchProduct(query: string) {
    this.router.navigateToRoute("searchResult", {
      query: Utils.encode(query),
      page: 1,
      type: SearchType.Precise,
    });
  }

  toggleProductList(target: HTMLElement, order: ICart) {
    $(() => {
      // Check if child element was clicked instead
      if (
        $(target).prop("tagName") != "TD" &&
        $(target).prop("tagName") != "TR"
      )
        return;

      const orderIdElement = document.querySelector(
        `[data-product-list="${order.id}"]`
      );
      if (orderIdElement)
        new Collapse(orderIdElement, { parent: "#accordion" }).toggle();
    });
  }

  convertToCart(order: any) {
    this.orderService
      .convertToCart(order.id)
      .then(() => {
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
        Utils.showSuccessToast(
          this.i18n.tr("pages.orders.messages.convert-to-cart")
        );
      })
      .catch((error) =>
        Utils.showErrorToast(
          log,
          Utils.getErrorMessage(error, this.i18n)
        )
      );
  }
  // #endregion

  // #region HELPERS

  sortedListener() {
    $(() => {
      $(".sortable").on("sorted", function () {
        // Move product list rows along with their parent order row when sorted
        $(this)
          .find(".product-list-row")
          .each(function () {
            $(this).insertAfter(
              $(`.order-row[data-order="${$(this).attr("data-order")}"]`)
            );
          });
      });
    });
  }

  // #endregion
}
