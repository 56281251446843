import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { I18N } from "aurelia-i18n";
import { IProduct } from "domain/Product/IProduct";
import { IResult } from "domain/IResult";
import { Utils } from "utils/helpers/utils";
import { AppConfig } from "app-config";
import { ICar } from "domain/Car/ICar";
import { CarService } from "services/car/car-service";
import { CatalogueService } from "services/car/catalogue-service";
import { Router } from "aurelia-router";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { SearchOrder } from "utils/enums/SearchOrder";

export const log = LogManager.getLogger("app.catalogue.products");

@autoinject
export class Products {
  vehicleId: number;
  catalogueId: number;
  catalogueName: string;
  query: string;

  result: IResult<IProduct> | undefined;
  car: ICar;

  error: string;
  isCarLoaded = false;

  @observable({ changeHandler: "parametersChanged" }) orderBy: SearchOrder =
    SearchOrder.Default;
  @observable({ changeHandler: "parametersChanged" }) orderReversed = false;
  @observable({ changeHandler: "parametersChanged" }) currentPage = 1;
  @observable filter: string;
  options = {
    showAmount: false,
    showSum: false,
    showAddToCart: true,
    showRemoveFromCart: false,
  };

  constructor(
    private catalogueService: CatalogueService,
    private carService: CarService,
    private router: Router,
    private appConfig: AppConfig,
    private i18n: I18N,
    private eventAggregator: EventAggregator
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadCar();
      this.loadProducts();
    });
  }

  activate(parameters: any) {
    this.vehicleId = parameters.vehicleId;
    this.catalogueId = parameters.catalogueId;
    this.catalogueName = decodeURIComponent(parameters.catalogueName);

    if (parameters.query) this.query = decodeURIComponent(parameters.query);
    if (parameters.filterText)
      this.filter = decodeURIComponent(parameters.filterText);

    const orderBy = parseInt(parameters.orderBy) || SearchOrder.Default;
    const orderReversed = parameters.orderReversed == "true";

    this.orderBy = orderBy;
    this.orderReversed = orderReversed;

    this.loadCar();
    this.loadProducts();
  }

  filterChanged(newValue: string, oldValue: string) {
    if (oldValue === undefined || !newValue) return;

    const parameters = {
      vehicleId: this.vehicleId,
      filterText: Utils.encode(newValue, true),
    } as any;
    if (this.query) parameters.query = Utils.encode(this.query, true);

    this.router.navigateToRoute("catalogueList", parameters);
  }

  parametersChanged(newValue: number, oldValue: number) {
    if (oldValue === undefined || newValue == undefined) return;

    // Clear the old products so the loading icon could activate again
    this.result = undefined;

    const parameters = {
      vehicleId: this.vehicleId,
      catalogueId: this.catalogueId,
      catalogueName: Utils.encode(this.catalogueName),
      page: this.currentPage,
    } as any;
    if (this.filter) parameters.filterText = Utils.encode(this.filter, true);
    if (this.query) parameters.query = Utils.encode(this.query, true);
    if (this.orderBy != SearchOrder.Default) {
      parameters.orderBy = this.orderBy;
      parameters.orderReversed = this.orderReversed;
    }

    this.router.navigateToRoute("catalogueProducts", parameters);

    // Since navigating to the same route doesn't actually refresh the page
    this.loadProducts();
  }

  loadCar() {
    if (this.isCarLoaded || !this.vehicleId || !this.appConfig.userSettings)
      return;

    // Try to get the car from cache
    const car = this.appConfig.getSelectedCar();

    // If cached car exists and it has the right ID, we don't need to query it again
    if (car && car.id == this.vehicleId) {
      this.car = car;
    } else {
      this.carService
        .fetchCar(this.vehicleId)
        .then((result) => {
          this.car = result;
        })
        .catch(() =>
          Utils.showErrorToast(log, this.i18n.tr("pages.car.errors.load.car"))
        );
    }

    this.isCarLoaded = true;
  }

  loadProducts() {
    if (this.result || !this.vehicleId || !this.appConfig.userSettings) return;

    this.catalogueService
      .fetchProducts(
        this.vehicleId,
        this.catalogueId,
        this.currentPage,
        this.orderBy,
        this.orderReversed
      )
      .then((result) => {
        this.result = result;
      })
      .catch((error) => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }
}
