import { IdentityService } from "services/identity/identity-service";
import { LogManager, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { TKError } from "utils/enums/TKError";

export const log = LogManager.getLogger("app.user.logout");

@autoinject
export class Logout {
  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {}

  activate(parameters: any): void {
    this.identityService.logout();

    if (parameters.error && parameters.error == TKError.sessionExpired) {
      this.router.navigateToRoute("userLogin", {
        error: TKError.sessionExpired,
      });
    } else {
      this.router.navigateToRoute("userLogin");
      location.reload();
    }
  }
}
