import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { AppConfig } from 'app-config';
import { IParentItem } from 'domain/Base/IParentItem';
import { CarService } from 'services/car/car-service';
import { ICar } from 'domain/Car/ICar';
import { CatalogueService } from 'services/car/catalogue-service';
import { Router } from 'aurelia-router';
import { TaskQueue, LogManager, autoinject, observable } from "aurelia-framework";
import $ from 'jquery';
import 'bootstrap';

export const log = LogManager.getLogger('app.catalogue.list');

@autoinject
export class List {
  car: ICar;
  catalogues: IParentItem[];

  @observable filter: string;
  error: string;

  vehicleId: number;
  query: string | undefined;

  catalogueId: number;
  visibleCatalogues: IParentItem[];
  chunkedCatalogues: IParentItem[][];

  constructor(
    private catalogueService: CatalogueService,
    private carService: CarService,
    private router: Router,
    private appConfig: AppConfig,
    private i18n: I18N,
    private taskQueue: TaskQueue
  ) { }

  activate(parameters: any) {
    this.vehicleId = parameters.vehicleId;

    if (parameters.catalogueId)
      this.catalogueId = parameters.catalogueId;

    if (parameters.filterText)
      this.filter = decodeURIComponent(parameters.filterText);

    if (parameters.query) {
      this.query = decodeURIComponent(parameters.query);
      this.carService.fetchCarWithQuery(parameters.vehicleId, parameters.query).then(result => this.carResult(result));
    } else {
      this.carService.fetchCar(parameters.vehicleId).then(result => this.carResult(result));
    }

    this.loadCatalogues(parameters.vehicleId);
  }

  attached() {
    this.filterCatalogues();
  }

  filterChanged(newValue: string, oldValue: string) {
    this.filterCatalogues();

    if (oldValue === undefined)
      return;

    const parameters = {
      vehicleId: this.vehicleId
    } as any;
    if (newValue)
      parameters.filterText = Utils.encode(newValue, true);
    if (this.query)
      parameters.query = Utils.encode(this.query, true);

    this.router.navigateToRoute('catalogueList', parameters, { trigger: false, replace: true });
  }

  // #region LOAD

  loadCatalogues(vehicleId: number) {
    this.catalogueService.fetchCatalogues(vehicleId).then(result => {
      this.catalogues = result;
      this.filterCatalogues();
    }).catch(error => this.error = Utils.getErrorMessage(error, this.i18n));
  }

  // #endregion

  loadAccordionsState() {
    const catalogueId = this.catalogueId;

    if (catalogueId && !this.filter) {
      const collapsibles = $("[data-item-id='" + catalogueId + "']");
      const carDetails = $("#car-details");

      // Hide car details if it exists
      if (catalogueId)
        carDetails.removeClass("show");

      // Open catalogues
      collapsibles.parents().addClass("show");

      // Set the catalogue ID value to 0 so it wouldn't complicate filtering items
      this.catalogueId = 0;
    }
  }

  // #region ACTIONS
  filterCatalogues() {
    if (!this.catalogues)
      return;

    // Clear old catalogues - required for 3+ level subcatalogues to properly open
    this.chunkedCatalogues = [];

    // Wait for Aurelia to finish clearing the DOM
    this.taskQueue.queueMicroTask(() => this.updateCatalogues());
  }

  updateCatalogues() {
    const currentFilter = this.filter?.toLowerCase();

    this.visibleCatalogues = currentFilter
      ? Utils.deepFilterItems(this.catalogues, currentFilter)
      : this.catalogues;
    this.chunkedCatalogues = Utils.chunkify(this.visibleCatalogues, 3, true);

    // Wait for Aurelia to finish populating the DOM
    this.taskQueue.queueMicroTask(() => {
      this.showFilteredCatalogues();
      this.loadAccordionsState();
      this.fixBorders();
    });
  }

  openProducts(catalogue: IParentItem,) {
    const parameters = {
      vehicleId: this.car.id,
      catalogueId: catalogue.id,
      catalogueName: Utils.encode(catalogue.name),
      page: 1
    } as any;

    if (this.query)
      parameters.query = Utils.encode(this.query, true);
    if (this.filter)
      parameters.filterText = Utils.encode(this.filter, true);

    this.router.navigateToRoute('catalogueProducts', parameters);
  }

  // #endregion

  // #region HELPERS

  carResult(result: ICar) {
    this.car = result;
    this.appConfig.addCar(this.car);
  }

  showFilteredCatalogues() {
    const currentFilter = this.filter?.toLowerCase();
    const collapsibles = $('#catalogues').find("div.collapse");

    if (currentFilter) {
      const carDetails = $("#car-details");

      // Hide car details if it exists
      if (carDetails)
        carDetails.removeClass("show");

      // Open catalogues
      collapsibles.addClass("show");

      // Let user open & close catalogues as they please
      collapsibles.removeAttr('data-parent');
    } else {
      // Close catalogues
      collapsibles.removeClass("show");
    }
  }

  fixBorders() {
    $(() => {
      $(".accordion").each(function () {
        $(this).children('.card').removeClass("accordion-card-visible-last");
        $(this).children('.card:not([style*="display: none"]):last').addClass("accordion-card-visible-last");
      });
    });
  }

  // #endregion
}
