import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { LogManager, autoinject, observable } from "aurelia-framework";
import { Utils } from "utils/helpers/utils";
import $ from "jquery";
import "bootstrap";
import "bootstrap-sortable";
import { CarCategoryService } from "services/car/car-category-service";
import { CarMenuLevel } from "utils/enums/CarMenuLevel";
import { IModel } from "domain/Car/IModel";

export const log = LogManager.getLogger("app.category.models");

@autoinject
export class Models {
  categoryId: number;
  manufacturerId: number;

  manufacturerName: string;
  models: IModel[];

  car: {
    model: {
      manufacturer: {
        id: number;
        name: string;
      };
    };
  };
  level = CarMenuLevel.Models;

  error: string;
  @observable filter: string;

  constructor(
    private carCategoryService: CarCategoryService,
    private router: Router,
    private i18n: I18N
  ) {}

  activate(parameters: any) {
    this.categoryId = parseInt(parameters.categoryId);

    if (isNaN(parameters.manufacturer))
      this.manufacturerName = decodeURIComponent(parameters.manufacturer);
    else this.manufacturerId = parseInt(parameters.manufacturer);

    this.loadModelsAndCar();
  }

  // #region MAIN

  filterChanged(term: string) {
    term = term.toLowerCase();

    $(() => {
      $(".model").each(function () {
        $(this).toggle($(this).text().toLowerCase().indexOf(term) > -1);
      });
    });
  }

  // #endregion

  // #region LOAD

  loadModelsAndCar() {
    this.carCategoryService
      .fetchModels(
        this.categoryId,
        this.manufacturerId ?? this.manufacturerName
      )
      .then((result) => {
        this.manufacturerName = result[0].manufacturer.name;
        this.models = result;

        this.car = {
          model: {
            manufacturer: {
              id: this.manufacturerId,
              name: this.manufacturerName,
            },
          },
        };

        Utils.loadSortable();
      })
      .catch((error) => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  openVehicles(model: IModel) {
    this.router.navigateToRoute("categoryVehicles", {
      categoryId: this.categoryId,
      manufacturer: this.manufacturerId ?? Utils.encode(this.manufacturerName),
      model: Utils.encode(model.name),
    });
  }

  // #endregion
}
