import { Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { SearchType } from "utils/enums/SearchType";
import { AppConfig } from "app-config";
import { EventAggregator } from "aurelia-event-aggregator";
import { TKEvent } from "utils/enums/TKEvent";
import { IdentityService } from "services/identity/identity-service";
import { UserService } from "services/identity/user-service";
import { PriceSetting } from "utils/enums/PriceSetting";
import { WarehouseService } from "services/client/warehouse-service";
import { IWarehouse } from "domain/Client/IWarehouse";
import { LogManager, autoinject } from "aurelia-framework";
import { Utils } from "utils/helpers/utils";
import { ISettings } from "domain/Settings/ISettings";

export const log = LogManager.getLogger("app.user.index");

@autoinject
export class Index {
  warehouses: IWarehouse[];

  changePasswordForm: HTMLFormElement;
  currentPassword: string;
  newPassword: string;

  priceSettings = {
    retail: PriceSetting.Retail,
    wholesale: PriceSetting.Wholesale,
  };

  // user settings
  vatIncludedInRetail: boolean;
  vatIncludedInWholesale: boolean;
  priceSetting: PriceSetting;
  warehouseId: number;
  itemsInProductPage: string;
  itemsInOrderPage: string;
  itemsInInvoicePage: string;
  itemsInReturnPage: string;
  imagesInProductPage: boolean;
  detailsInProductPage: boolean;
  defaultSearchLimitation: SearchType;

  // contract settings
  useContractCart: boolean;
  otherTransportAddresses: string;

  constructor(
    private userService: UserService,
    private identityService: IdentityService,
    private warehouseService: WarehouseService,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig,
    private i18n: I18N,
    private router: Router
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      this.loadSettings();
    });
  }

  activate() {
    // constructor was too soon, login check hadn't kicked in yet
    this.loadWarehouses();
    this.loadSettings();
  }

  //#region LOAD

  loadWarehouses() {
    this.warehouseService
      .getWarehouses()
      .then((result) => (this.warehouses = result));
  }

  loadSettings() {
    const userSettings = this.appConfig.userSettings;
    const contractSettings = this.appConfig.contractSettings;

    if (!userSettings || !contractSettings) return;

    // user settings
    this.vatIncludedInRetail = userSettings.vatIncludedInRetail;
    this.vatIncludedInWholesale = userSettings.vatIncludedInWholesale;
    this.priceSetting = userSettings.priceSetting;
    this.warehouseId = userSettings.warehouseId;
    this.itemsInProductPage = userSettings.itemsInProductPage.toString();
    this.itemsInOrderPage = userSettings.itemsInOrderPage.toString();
    this.itemsInInvoicePage = userSettings.itemsInInvoicePage.toString();
    this.itemsInReturnPage = userSettings.itemsInReturnPage.toString();
    this.imagesInProductPage = userSettings.imagesInProductPage;
    this.detailsInProductPage = userSettings.detailsInProductPage;
    this.defaultSearchLimitation = userSettings.defaultSearchLimitation;

    // contract settings
    this.useContractCart = contractSettings.useContractCart;
    this.otherTransportAddresses = this.getStringFromRows(
      contractSettings.otherTransportAddresses
    );
  }

  //#endregion

  //#region ACTIONS

  checkPasswordValidity() {
    Utils.checkPasswordValidity(this.i18n);
  }

  submitPasswordChange() {
    this.identityService
      .changePassword(this.currentPassword, this.newPassword)
      .then(() => {
        Utils.showSuccessToast(
          this.i18n.tr("pages.user.settings.sections.password.messages.submit")
        );

        this.changePasswordForm.reset();
      })
      .catch((error) =>
        Utils.showErrorToast(
          log,
          this.i18n.tr("pages.user.settings.sections.password.errors.submit")
        )
      );
  }

  submitSettings() {
    const userSettings = this.appConfig.userSettings;
    const contractSettings = this.appConfig.contractSettings;

    // user settings
    userSettings.vatIncludedInRetail = this.vatIncludedInRetail;
    userSettings.vatIncludedInWholesale = this.vatIncludedInWholesale;
    userSettings.priceSetting = this.priceSetting;
    userSettings.warehouseId = this.warehouseId;
    userSettings.itemsInProductPage = parseInt(this.itemsInProductPage);
    userSettings.itemsInOrderPage = parseInt(this.itemsInOrderPage);
    userSettings.itemsInInvoicePage = parseInt(this.itemsInInvoicePage);
    userSettings.itemsInReturnPage = parseInt(this.itemsInReturnPage);
    userSettings.imagesInProductPage = this.imagesInProductPage;
    userSettings.detailsInProductPage = this.detailsInProductPage;
    userSettings.defaultSearchLimitation = this.defaultSearchLimitation;

    // contract settings
    contractSettings.useContractCart = this.useContractCart;
    contractSettings.otherTransportAddresses = this.getRowsFromString(
      this.otherTransportAddresses
    );

    const settings: ISettings = {
      userSettings,
      contractSettings,
    };

    this.userService
      .setSettings(settings)
      .then(() => {
        this.appConfig.setSettings(settings);
        Utils.showSuccessToast(
          this.i18n.tr(
            "pages.user.settings.sections.preferences.messages.submit"
          )
        );

        // Update cart: maybe price settings were changed
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch(() =>
        Utils.showErrorToast(
          log,
          this.i18n.tr("pages.user.settings.sections.preferences.errors.submit")
        )
      );
  }

  //#endregion

  //#region HELPERS

  getStringFromRows(rows: string[]): string {
    rows = Utils.unique(rows);
    rows = Utils.removeEmpty(rows);
    return rows.join("\n");
  }

  getRowsFromString(str: string): string[] {
    let rows = str.split("\n");
    rows = Utils.unique(rows);
    return Utils.removeEmpty(rows);
  }

  //#endregion
}
